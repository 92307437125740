/** @jsx jsx */
import React from "react";
import Img from "gatsby-image";
import { jsx, Link as TLink } from "theme-ui";
import { Box } from "@theme-ui/components";
import { Link } from "gatsby";
import ItemTags from "./item-tags";
import PostCard from "./postCard";
export type BlogListItemProps = {
  post: {
    slug: string;
    title: string;
    date: string;
    banner: any;
    excerpt: string;
    description: string;
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
  };
  showTags?: boolean;
};

const BlogListItem = ({ post, showTags = true }: BlogListItemProps) => (
  <div
    className="post-card-container"
    style={{
      width: '50%',
      padding: '0 20px',
      marginBottom: '30px'
    }}
  >
    <Box mb={4}>
      <TLink as={Link} to={post.slug} sx={{ fontSize: [1, 2, 3], color: `text` }}>
        <div
          className="post-card-image"
          style={{
            margin: `0 0 10px 0`,
            width: `100%`,
            height: `auto`,
          }}
        >
          <img width="100%" src={(post.banner) ? post.banner.publicURL : ""} style={{ borderRadius: "6px" }} />
        </div>
        {post.title}
      </TLink>
      <p
        sx={{
          color: `secondary`,
          mt: 1,
          a: { color: `secondary` },
          fontSize: [1, 1, 2],
        }}
      >
        {post.tags && (
          <React.Fragment>
            <ItemTags tags={post.tags} />
          </React.Fragment>
        )}
      </p>
    </Box>
  </div>
);

export default BlogListItem;
